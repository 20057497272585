@import 'react-quill/dist/quill.snow.css';
@import 'quill-image-uploader/dist/quill.imageUploader.min.css';

.ql-editor {
  font-size: 16px;
  word-break: break-word;
}

.quill .ql-editor > * {
  cursor: inherit;
}

.quill.h-small > .ql-container > .ql-editor {
  height: 95px;
}

.quill.min-h-small > .ql-container > .ql-editor {
  min-height: 95px;
}

.quill.min-h > .ql-container > .ql-editor {
  min-height: 150px;
}

.ql-editor ul > li::before {
  font-size: 24px;
  line-height: 1;
  width: 1.2em;
  vertical-align: middle;
}

.ql-editor ol,
.ql-editor ul {
  padding-left: 0;
}

.ql-editor img {
  @apply border border-neutral-300;
}

.ql-tooltip {
  z-index: 1;
}

.ql-editor .mention {
  @apply text-blue-600;
}

.ql-toolbar.ql-snow {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom-color: rgba(0, 68, 204, 0.2);
}

.ql-container.ql-snow {
  border: 0;

  img {
    cursor: zoom-in;
  }
}

.quill.no-toolbar .ql-toolbar {
  display: none;
}
.quill.comment-styles .ql-container {
  font-family: inherit;
}

.quill.comment-styles .ql-editor {
  font-size: 14px;
  padding: 0;
}

.quill.resume .ql-editor {
  max-height: 38px;
  @apply line-clamp-2;
}

.quill.resume .ql-editor img {
  display: none;
}

.quill.resume.resume-one-line .ql-editor {
  max-height: 18px;
  @apply line-clamp-1;
}

.quill.resume.resume-inbox .ql-editor {
  max-height: 57px;
  @apply line-clamp-3;
}

.ql-container .ql-mention-list-container {
  @apply z-50 overflow-auto rounded border bg-white;

  .ql-mention-list {
    @apply py-2;
    max-height: 250px;
    min-width: 220px;
    max-width: 350px;
  }

  .ql-mention-list-item {
    @apply cursor-pointer px-4 py-1;
  }

  .ql-mention-list-item.selected {
    @apply bg-blue-600 text-white;
  }
}

.ql-editor.ql-blank::before {
  font-style: normal;
}

.yarl__container,
.yarl__thumbnails_container {
  background-color: rgba(0, 0, 0, 0.8);
}

.yarl__thumbnails_vignette {
  display: none;
}

.quill.quill-scroll-small {
  .ql-container {
    height: 337px;
  }
}

.quill.quill-clean-container {
  border-radius: 0 !important;
  border: none !important;
}

.quill-content-show {
  @apply absolute bg-white pb-1;
  width: calc(100% - 16px);
  bottom: 1px;
  left: 8px;
}

.quill.quill-reduced-size .ql-editor {
  height: 160px;
  overflow: hidden;
}

.quill.quill-full-size .ql-editor {
  padding-bottom: 35px;
}

.quill-content-show.quill-content-show--more {
  box-shadow: 0 -10px 25px 10px rgb(255 255 255 / 85%);
}

.quill.deleted-message {
  font-style: italic;
  opacity: 0.3;
}
