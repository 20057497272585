@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'zephyr';
  src: url('./assets/fonts/zephyr-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.text-zephyr {
  font-family: 'zephyr', serif;
}

html,
body {
  @apply bg-blue-50;
}

.emoji-outline {
  text-shadow: 0 0 1px gray, -1px -1px 1px gray, -1px 1px 1px gray,
    1px 1px 1px gray, 1px -1px 1px gray;
}

.emoji-outline[title]:before {
  content: attr(title);
  position: absolute;
  width: 100%;
  text-shadow: 0 0 0 #fff;
}

.read-only {
  @apply pointer-events-none;
}

.ant-input:read-only {
  cursor: default;
}

.ant-input:read-only:focus {
  border-color: #d9d9d9;
  box-shadow: none;
}

.ant-input:read-only:hover {
  border-color: #d9d9d9;
}

.show-in-print {
  display: none !important;
}

@media print {
  body {
    background-color: white;
  }

  .show-in-print {
    display: block !important;
  }

  .hide-in-print {
    display: none !important;
  }

  .print-form-styles {
    margin: 70px 50px !important;
  }

  .print-form-styles
    .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required::before {
    display: none !important;
  }

  .print-form-styles .default-form *:not(.ant-input-affix-wrapper) > .ant-input,
  .print-form-styles .default-form .ant-select .ant-select-selector,
  .print-form-styles .default-form .ant-picker-range,
  .print-form-styles .default-form .ant-picker.ant-picker-borderless,
  .print-form-styles .quill:not(.no-container) {
    border: none !important;
  }

  .print-form-styles .ant-form-item-control-input-content .ant-input {
    resize: none;
    min-height: auto !important;
  }

  .print-form-styles .ant-select .ant-select-arrow {
    display: none;
  }

  .print-form-styles .ant-picker {
    border: none !important;
  }

  .print-form-styles .ant-picker .ant-picker-suffix {
    display: none;
  }
}

.ant-form.ant-form-horizontal .ant-form-item .ant-form-item-label {
  white-space: initial;
}

.ant-form.ant-form-horizontal .ant-form-item .ant-form-item-label > label {
  height: auto;
}

.global-search-item {
  padding: 20px;
}

.global-search-item:nth-child(even) {
  background: rgba(0, 68, 204, 0.05) !important;
}

.global-search-item:nth-child(even) .global-search-data:hover {
  background-color: rgba(0, 68, 204, 0.2);
}

.global-search-item .global-search-data {
  padding: 8px 0;
  padding-left: 10px;
  transition: all 0.2s;
  cursor: pointer;
}

.global-search-item .global-search-data:hover {
  background-color: #ececec;
}

.replyTo {
  width: 100%;
  background-color: rgb(243, 243, 243);
  border-radius: 4px;
  padding: 10px 15px;
  margin-top: 5px;
  position: relative;
  overflow: hidden;
}

.replyTo .line {
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: #20bb84;
}

.replyTo h3 {
  margin: 0px !important;
}

.replyTo p {
  margin-bottom: 0;
}

.btn-green {
  @apply !border-[#52C41A] !bg-[#52C41A] !text-white hover:opacity-[0.7];
}

.ant-btn.btn-green-outline:not(:disabled) {
  @apply !border-[#52C41A] bg-[#f5ffe8] text-[#52C41A] hover:bg-[#52C41A] hover:!text-white;
}

.ant-btn.btn-red-outline:not(:disabled) {
  @apply !border-[#F5222D] bg-[#feedec] text-[#F5222D] hover:bg-[#F5222D] hover:!text-white;
}

.ant-btn.btn-orange-outline:not(:disabled) {
  @apply !border-[#FA8C16] bg-orange-50 text-[#FA8C16] hover:bg-[#FA8C16] hover:!text-white;
}

@media (max-width: 767px) {
  .btn-icon-left-md {
    display: flex !important;
    align-items: center;
  }

  .btn-icon-left-md > *:nth-child(2) {
    flex: 1 1 auto;
  }
}

.btn-green:disabled {
  @apply opacity-[0.5];
}

.ant-drawer-content-wrapper {
  transition-property: transform !important;
}

@media only screen and (max-width: 800px) {
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.blue-labels .ant-form-item-label label {
  @apply !text-blue-600;
}

.default-form .ant-form-item-label label {
  color: #0044cc !important;
  font-weight: 600 !important;
}

.default-form .ant-form-item-label.ant-col {
  padding: 0;
}

.default-form .ant-form-item {
  margin-bottom: 6px;
}

.form-label-no-bold .ant-form-item-label.ant-col label {
  font-weight: normal !important;
}

.default-form *:not(.ant-input-affix-wrapper) > .ant-input,
.default-form .ant-select .ant-select-selector,
.default-form .ant-picker-range,
.default-form .ant-picker.ant-picker-borderless,
.quill:not(.no-container) {
  background-color: white !important;
  border-radius: 4px !important;
  border: 1px solid rgba(0, 68, 204, 0.2) !important;
  box-shadow: none !important;
}

.default-form .ant-input-affix-wrapper {
  background-color: white !important;
  border-radius: 4px !important;
  border: 1px solid rgba(0, 68, 204, 0.2) !important;
  overflow: hidden !important;
}

.default-form .ant-select-dropdown {
  border-radius: 4px !important;
}

.default-form.task-form .ant-form-item .ant-form-item-label > label {
  height: 26px;
}

.ant-collapse-header-padding-none .ant-collapse-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ant-collapse.ant-collapse-borderless
  > .ant-collapse-item
  > .ant-collapse-header {
  align-items: center;
}

.ant-collapse-list-gray
  .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  background: #f8fafc;
  border-radius: 4px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ant-comment-inner {
  padding: 12px !important;
}

.ant-modal-footer {
  margin-top: 0 !important;
}

.header__select .ant-select-selector {
  border-radius: 4px !important;
}

.codex-editor__redactor {
  padding-bottom: 0 !important;
}

.ant-upload-wrapper .ant-upload-drag .ant-upload.ant-upload-btn {
  padding: 8px 0;
}

.ant-upload-list-picture {
  width: 100%;
  margin-top: 8px;
  overflow: auto;
  display: flex;
}

.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture
  .ant-upload-list-item-container
  .ant-upload-list-item {
  background: white;
  border-radius: 4px;
  margin: 0;
}

.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture
  .ant-upload-list-item-container
  .ant-upload-list-item:hover {
  background: white;
}

.ant-upload-list-picture .ant-upload-list-item-container {
  padding-right: 8px;
}

.ant-upload-list-picture
  .ant-upload-list-item-container
  .ant-upload-list-item-actions
  .ant-btn.ant-btn-sm {
  color: inherit;
  font-size: 12px;
}

.ant-upload-list-picture .ant-upload-list-item-container:last-child {
  padding-right: 0;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail {
  display: flex;
  align-items: center;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail > img {
  height: auto !important;
}

.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture
  .ant-upload-list-item
  div.ant-upload-list-item-progress {
  bottom: 0 !important;
}

.ant-upload-wrapper.clean-drag-area .ant-upload-drag {
  border: none;
  background: transparent;
}

.ant-upload-wrapper.clean-drag-area .ant-upload-drag .ant-upload-btn {
  padding: 0;
}

.ant-upload-wrapper.no-files .ant-upload-list {
  display: none;
}

.ant-btn {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}

.ant-btn.ant-btn-default {
  border: 1px solid rgba(0, 68, 204, 0.2);
  color: #04c;
}

.ant-btn.truncate > span {
  display: inline !important;
}

.ant-popover-disabled-compatible-wrapper .ant-btn {
  width: inherit;
}

.app-tour-wrapper.ant-tour {
  max-width: 100%;
  padding: 0 8px;
}

@media (max-width: 650px) {
  .app-tour-wrapper.ant-tour {
    width: 80%;
  }
}

.like-outline {
  color: transparent !important;
  text-shadow: 0 0 1px gray, -1px -1px 1px gray, -1px 1px 1px gray,
    1px 1px 1px gray, 1px -1px 1px gray;
  position: relative;
}

.like-outline:before {
  content: attr(data-like);
  position: absolute;
  width: 100%;
  text-shadow: 0 0 0 #fff;
}

#sentry-feedback .widget__actor {
  display: none;
}

.ant-dropdown .ant-dropdown-menu > .ant-dropdown-menu-item.menu-item-separator {
  padding: 0;
  margin: 4px 0;
  border-top: 1px solid #e2e8f0;
}

.ant-dropdown
  .ant-dropdown-menu
  > .ant-dropdown-menu-item.menu-item-separator:hover {
  cursor: initial;
  background-color: inherit;
}

.ant-menu-item.menu-item-bg-danger,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item.menu-item-bg-danger,
.ant-menu-light .menu-item-bg-danger.ant-menu-item-selected {
  @apply bg-red-500 text-white;
}

.ant-menu-light:not(.ant-menu-horizontal)
  .ant-menu-item.menu-item-bg-danger:not(.ant-menu-item-selected):hover,
.ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item.menu-item-bg-danger:hover {
  @apply bg-red-600 text-white;
}

.video-modal.ant-modal-wrap .ant-modal {
  max-width: 100vw;
}

.video-modal.ant-modal-wrap .ant-modal .ant-modal-content {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0 6px;
}

@media screen and (max-width: 640px) {
  .ant-dropdown.ant-dropdown-fullscreen-sm {
    position: fixed;
    left: 0 !important;
    width: 100%;
  }

  .ant-dropdown.ant-dropdown-fullscreen-sm > .ant-dropdown-arrow {
    display: none;
  }

  .ant-dropdown.ant-dropdown-fullscreen-sm > div:not(.ant-dropdown-arrow) {
    padding-top: 12px;
    padding-bottom: 12px;
    height: 100vh;
    box-shadow: none !important;
    border-radius: 0 !important;
  }

  .sm-ant-dropdown-text-xl.ant-dropdown
    .ant-dropdown-menu
    .ant-dropdown-menu-item {
    font-size: 20px;
  }
}

.ant-drawer .ant-drawer-mask {
  width: 100% !important;
  height: 100% !important;
  inset: unset !important;
  top: 0 !important;
  left: 0 !important;
}

.ant-avatar.ant-avatar-square,
.ant-avatar.ant-avatar-square.ant-avatar-sm,
.ant-avatar.ant-avatar-square.ant-avatar-lg {
  border-radius: 5px;
}

.ant-avatar:not(.ant-avatar-sm):not(.ant-avatar-lg) {
  width: 28px;
  height: 28px;
  line-height: 28px;
}

.ant-avatar.ant-avatar-lg {
  width: 34px;
  height: 34px;
  line-height: 34px;
}

.ant-avatar > svg {
  display: inline-block;
  vertical-align: unset;
}

.ant-checkbox-group.checkbox-group-square .ant-checkbox-wrapper .ant-checkbox,
.ant-radio-group.radio-group-square .ant-radio-wrapper .ant-radio {
  display: none;
}

.ant-checkbox-group.checkbox-group-pill
  .ant-checkbox-wrapper
  .ant-checkbox
  .ant-checkbox-inner,
.ant-radio-group.radio-group-pill
  .ant-radio-wrapper
  .ant-radio
  .ant-radio-inner {
  border-radius: 50%;
}

.ant-checkbox-group.checkbox-group-pill
  .ant-checkbox-wrapper
  .ant-checkbox.ant-checkbox-checked
  .ant-checkbox-inner,
.ant-checkbox-group.checkbox-group-pill
  .ant-checkbox-wrapper:hover
  .ant-checkbox.ant-checkbox-checked
  .ant-checkbox-inner,
.ant-radio-group.radio-group-pill
  .ant-radio-wrapper:hover
  .ant-radio.ant-radio-checked
  .ant-radio-inner,
.ant-radio-group.radio-group-pill
  .ant-radio-wrapper
  .ant-radio.ant-radio-checked
  .ant-radio-inner {
  background-color: #fff;
  border-color: #fff;
}

.ant-radio-group.radio-group-pill .ant-radio-wrapper .ant-radio-inner:after {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  inset-inline-start: 21.5%;
  display: table;
  width: 5.7142857142857135px;
  height: 9.142857142857142px;
  border: 2px solid #fff;
  border-top: 0;
  border-inline-start: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  content: '';
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  margin-block: 0;
  margin-inline: 0;
  border-radius: 0;
}

.ant-checkbox-group.checkbox-group-pill
  .ant-checkbox-wrapper
  .ant-checkbox.ant-checkbox-checked
  .ant-checkbox-inner:after,
.ant-radio-group.radio-group-pill
  .ant-radio-wrapper
  .ant-radio.ant-radio-checked
  .ant-radio-inner:after {
  border-color: #0044cc;
  opacity: 1;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.ant-checkbox-group.checkbox-group-pill
  .ant-checkbox-wrapper
  .ant-checkbox.ant-checkbox-checked:after,
.ant-radio-group.radio-group-pill
  .ant-radio-wrapper
  .ant-radio.ant-radio-checked:after {
  display: none;
}

.ant-checkbox-group.checkbox-group-pill .ant-checkbox-wrapper svg,
.ant-radio-group.radio-group-pill .ant-radio-wrapper svg,
.ant-checkbox-group.checkbox-group-square .ant-checkbox-wrapper svg,
.ant-radio-group.radio-group-square .ant-radio-wrapper svg {
  display: inline-block;
  font-size: 1.6em;
  margin-bottom: 4px;
}

.ant-checkbox-group.checkbox-group-pill,
.ant-checkbox-group.checkbox-group-square,
.ant-radio-group.radio-group-pill,
.ant-radio-group.radio-group-square {
  display: inline-flex;
  flex-wrap: wrap;
  row-gap: 6px;
}

.ant-checkbox-group.checkbox-group-pill .ant-checkbox-wrapper,
.ant-radio-group.radio-group-pill .ant-radio-wrapper {
  background: rgba(0, 68, 204, 0.05);
  border-radius: 4px;
  padding: 4px 10px;
}

.ant-checkbox-group.checkbox-group-square .ant-checkbox-wrapper,
.ant-radio-group.radio-group-square .ant-radio-wrapper {
  background: #f3f3f3;
  border-radius: 6px;
  padding: 20px 20px;
  /*width: 180px;*/
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  word-break: break-word;
}

.ant-checkbox-group.checkbox-group-pill
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked,
.ant-radio-group.radio-group-pill .ant-radio-wrapper.ant-radio-wrapper-checked,
.ant-checkbox-group.checkbox-group-square
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked,
.ant-radio-group.radio-group-square
  .ant-radio-wrapper.ant-radio-wrapper-checked {
  background: #0044cc;
  color: white;
}

.ant-checkbox-group.checkbox-group-pill.read-only
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked,
.ant-radio-group.radio-group-pill.read-only
  .ant-radio-wrapper.ant-radio-wrapper-checked,
.ant-checkbox-group.checkbox-group-square.read-only
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked,
.ant-radio-group.radio-group-square.read-only
  .ant-radio-wrapper.ant-radio-wrapper-checked {
  background: #6479a1;
}

.ant-picker.ant-picker-short-format {
  padding-right: 1.4em;
}
.ant-picker.ant-picker-short-format .ant-picker-clear {
  right: 0;
}

.ant-picker.ant-picker-short-format > .ant-picker-input {
  width: auto;
}

.ant-picker.ant-picker-short-format > .ant-picker-input > input {
  width: 6.1em;
}

.ant-optional-label.ant-form-item .ant-form-item-label:before {
  content: '(Optional)';
  display: block;
  color: rgba(0, 0, 0, 0.5);
  font-weight: normal;
  font-size: 0.9em;
  line-height: 1;
}

.ant-tabs.ant-tabs-top.tab-fullscreen
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap:not(.ant-tabs-nav-wrap-ping-right):not(
    .ant-tabs-nav-wrap-ping-left
  )
  > .ant-tabs-nav-list {
  flex: 1;
}

.ant-tabs.ant-tabs-top.tab-fullscreen
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap:not(.ant-tabs-nav-wrap-ping-right):not(
    .ant-tabs-nav-wrap-ping-left
  )
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  flex: 1 1 auto;
  justify-content: center;
}

.ant-tabs.tab-secondary
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list {
  color: #9a9a9a;
}

.ant-tabs.tab-compact > .ant-tabs-nav {
  margin: 0;
}

.ant-tabs.tab-no-border > .ant-tabs-nav::before {
  display: none;
}

.project-card-report {
  display: flex;
  display: none;
}
