@media (max-width: 620px) {
  .bl-dropdown-overlay {
    right: 0 !important;
    left: unset !important;
  }

  .bl-dropdown-content {
    margin-left: 12px;
    margin-right: 12px;
  }
}

.ant-dropdown.bl-dropdown-overlay {
  z-index: 950;
}

.bl-dropdown-content {
  background: #0044cc;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  width: calc(100vw - 24px);
  max-width: 500px;
  max-height: 500px;
  margin-top: 16px;
  z-index: 10;
  position: relative;
}

.bl-dropdown-header,
.bl-dropdown-footer {
  padding: 16px 24px;
  color: white;
  line-height: 1;
  display: flex;
  justify-content: space-between;

  button,
  button.ant-btn,
  button:focus {
    color: white;
    padding-top: 0;
    padding-bottom: 0;
  }

  button.ant-btn:not(:disabled):hover,
  button:hover {
    color: #e5e7eb;
  }

  .ant-radio-button-wrapper {
    &:hover {
      color: #22c55e;
    }
  }

  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: #22c55e;
    border-color: #22c55e;

    &::before {
      background-color: #22c55e;
    }

    &:hover {
      background-color: #4ade80;
      border-color: #4ade80;
    }

    &:hover::before {
      background-color: #4ade80;
    }

    &:active {
      background-color: #16a34a;
      border-color: #16a34a;
    }

    &:active::before {
      background-color: #16a34a;
    }
  }
}

.bl-dropdown-inside-popover.ant-popover {
  z-index: 960;
}

.bl-dropdown-body {
  overflow: auto;
  flex: 1 1 0;
  background-color: white;
  padding: 10px;
}

.bl-dropdown-body .infinite-scroll-component > .ant-dropdown-menu-root {
  padding: 0;
  background: none;
  box-shadow: none;
  border-radius: 0;
}

.bl-dropdown-body
  .infinite-scroll-component
  > .ant-dropdown-menu-root
  > .ant-dropdown-menu-item {
  padding: 0;
  border-radius: 0;
}

.bl-dropdown-body
  .infinite-scroll-component
  > .ant-dropdown-menu-root
  > .ant-dropdown-menu-item:hover {
  background: none;
}

.bl-dropdown-card {
  display: flex;
  padding: 10px 14px;
  gap: 6px;
  align-items: center;
  transition: background-color ease-in-out 200ms;
  border-radius: 8px;
  margin-bottom: 10px;

  > :first-child {
    flex: 1 1 auto;
    word-break: break-word;
    display: flex;
    gap: 14px;
    align-items: center;
    text-align: left;

    > :first-child {
      flex: 1 1 auto;
    }

    > :nth-child(2) {
      flex: 0 0 auto;
    }
  }

  article,
  span {
    font-size: 13px;
  }

  article {
    overflow: hidden;
    max-height: 41px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.06);
  }
}

.bl-dropdown-body .ant-dropdown-menu-item:last-child .bl-dropdown-card {
  margin-bottom: 0;
}

.bl-dropdown-card--unread {
  & {
    background: #ebf5ff;
  }

  &:hover {
    background: #bfdbfe;
  }
}

.bl-dropdown-card--title {
  font-weight: 500;
  margin-bottom: 2px;
}
