.progressbar-double {
  width: 100%;
  overflow: hidden;
  position: relative;
  background: rgba(0, 68, 204, 0.05);
  border: 1px solid rgba(0, 68, 204, 0.2);

  &.progressbar-double-danger {
    border: 1px solid rgba(255, 77, 79, 0.2);
  }

  &,
  .progressbar-double__background-bar,
  .progressbar-double__secondary-bar,
  .progressbar-double__main-bar {
    height: 7px;
    box-sizing: content-box;
    border-radius: 20px;
  }

  .progressbar-double__background-bar,
  .progressbar-double__secondary-bar,
  .progressbar-double__main-bar {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    min-width: 0;
  }

  .progressbar-double__background-bar {
    width: 100%;
  }

  .progressbar-double__secondary-bar {
    background: rgba(0, 68, 204, 0.2);

    &.progressbar-double__secondary-bar-danger {
      background: rgba(255, 77, 79, 0.2);
    }
  }

  .progressbar-double__main-bar {
    background: rgb(0, 68, 204);

    .progressbar-double__main-bar-danger {
      background: rgba(255, 77, 79);
    }
  }
}
